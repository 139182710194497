import Logo from './Logo'

import Content from './BannerContent';
function InnerBanner(){
    return <>
        <Logo/>
        <div className="inner-banner">
            <Content/>
        </div> 
    </>
}
export default InnerBanner