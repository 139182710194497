
export const BURKE_ADMIN_BASE_URL = 'https://admin-services.sabademoproject.com';
export const BURKE_BASE_API_URL   = BURKE_ADMIN_BASE_URL + '/api';
export const LOCAL_BURKE_BASE_API_URL = 'http://local.admin.burkecontractors.com/api';

// {/* Google Project for the API Key: https://console.cloud.google.com/welcome?project=ltc-austin */}
export const BURKE_GOOGLE_AUTOCOMPLETE_API_KEY = 'AIzaSyCdKtt0RUO6N_jUszQ2frNNgDyd95weHrI';

// https://console.cloud.google.com/google/maps-apis/api-list?project=burke-contractors
export const BURKE_GOOGLE_MAP_API_KEY = 'AIzaSyB1pgs4B0QEkz3s4rJh2fRoY6LNepjYyJ0';

export const LS_CUST_LAT = 'burke-customer-lat';
export const LS_CUST_LNG = 'burke-customer-lng';
export const LS_CUST_NAME = 'burke-customer-name';
export const LS_CUST_EMAIL = 'burke-customer-email';
export const LS_CUST_PHONE = 'burke-customer-phone';
export const LS_CUST_ADDRESS = 'burke-customer-address';
export const LS_JOB_URGENCY = 'burke-job-urgency';
export const LS_JOB_BUDGET = 'burke-job-budget';
export const LS_JOB_DESCRIPTION = 'burke-job-description';
export const LS_UPLOADED_MEDIA = 'burke-uploaded-media';

export const BURKE_URGENCY_TYPES = [
  'Within a Week',
  'Not Sure',
  'Need a Quote',
  'Flexible',
];
