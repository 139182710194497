function Footer (){
    const currentYear = new Date().getFullYear();
    return <>
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="footer-img">
                            <a href="https://burkecontractors.com">
                                <img src="assets/burke-contractors-logo.png" class="img-fluid mb-3" alt="Burke Contractors - Logo" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                            <p class="mb-2 font-weight-bold">© {currentYear} Burke Contractors</p> 
                            <p class="font-weight-bold">Designed By <a href="https://www.sabaseo.com/" target="_blank">Saba SEO</a></p>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Footer