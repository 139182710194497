import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Alert, ButtonGroup, Button } from 'reactstrap';

function Help() {
  






  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <h1 aria-label='Services'>Help</h1>
        <div className='subheading'>How to use the system and Frequently Asked Questions</div>
      </Row>
    </>
  }
  






  return <>

    <Container className='page page-services bg-gray' fluid>
      <HEADER />
      <Container>
        <Row>
          <Col md="12">
            
          </Col>
        </Row>
      </Container>
    </Container>
  </>
}
export default Help;