function WhyChooseBurke () {
    return <>
        <div className="why-choose-burke">
            <div className="container">
                <div className="inner-why-burke">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="why-brk-cont">
                                <div className="why-burk-heading">
                                    <h2>Why Choose Burke Contractors?</h2>
                                </div>
                                <div className="why-burk-para">
                                    <p>We believe hiring a great contractor should be easy. We are Committed to finding you the perfect contractor. We will be your one stop shop, pointing you to trustworthy contractors in your local area. Simply fill out from or give us a call at 858-208-7765 and we will send them to you. it's that easy. Here are some key things that set us apart:</p>
                                </div>
                                <div className="more-divided-section">
                                    <div className="row">
                                        <div className="col-md-6 p-0">
                                            <div className="more-div-img">
                                                <img src="assets/discuss.png" alt="discuss" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 p-0">
                                            <ul>
                                                <li>We Verify Our Contractors</li>
                                                <li>We Keep Your Job On Schedule</li>
                                                <li>We do the communicating</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default WhyChooseBurke