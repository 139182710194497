// Node Modules
import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Container, Alert } from 'reactstrap';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Project Components
import Navigation from './Components/Navigation';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import Service from './Pages/Service/Service';
import Register from './Pages/Register/Register';
import Providers from './Pages/Providers/Providers';
import ProviderProfile from './Pages/ProviderProfile/ProviderProfile';
import ProviderDirectory from './Pages/Providers/ProviderDirectory';
import Help from './Pages/Help';
import Terms from './Pages/Terms';
import Review from './Pages/Review/Review';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

// Project Constants
import { BURKE_BASE_API_URL } from './constants';
import { /*faExclamationCircle,*/ faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const SettingsContext = React.createContext(null);

function App() {
  // const settings = useContext(SettingsContext);

  const [isLoading, setLoading] = useState(true);
  const [isGetSettingsSuccess, setGetSettingsSuccess] = useState(false);
  const [theGetSettingsResponse, setGetSettingsResponse] = useState(false);
  const [theSettings, setSettings] = useState({});

  useEffect(() => {

    // document.title = 'Loading...';

    let params = '';
    let url = BURKE_BASE_API_URL + '/settings' + (params ? '?' + params : '');

    axios.get(url, {}, { timeout: 2 })
      .then(function (response) {

        setLoading(false);

        if (response.data.success) {
          setGetSettingsSuccess(true);
          setSettings(response.data.data);
        } else {
          setGetSettingsResponse('No data was received.');
        }

      })
      .catch(function (error) {
        setLoading(false);
        setGetSettingsResponse(error.message);
      });





  }, []);

  return <>
    {
      isLoading && <div class="loading-wrap"><InfinitySpin ariaLabel="loading" width='100' color="#0d6efd" /></div>

    }

    {
      !isLoading && <Router>
        <SettingsContext.Provider value={theSettings}>
          <div className="App">
            <Container className='nav-container border-b bg-white' fluid>
              <Container><Navigation /></Container>
            </Container>
            <Container className='page-body m0 p0' fluid>
              {
                !isGetSettingsSuccess && theGetSettingsResponse && <Container><Alert className='mt-4' color="warning"> <FontAwesomeIcon icon={faExclamationTriangle} /> {theGetSettingsResponse}</Alert></Container>
              }
              {
                <Routes>
                  {isGetSettingsSuccess  ? 
                  <>
                  <Route exact path='/' element={<Home />} />
                  <Route exact path='/services/:serviceID/providers/:providerID' element={<ProviderProfile />} />
                  <Route exact path='/services/:serviceID/providers' element={<Providers />} />
                  <Route exact path='/services/:serviceID' element={<Service />} />
                  <Route exact path='/services' element={<Services />} />
                  <Route exact path='/providers-directory' element={<ProviderDirectory/>}   />
                  <Route exact path='/providers/:providerID' element={<ProviderProfile />} />
                  <Route exact path='/register' element={<Register />} />
                  <Route exact path='/help' element={<Help />} />
                  <Route exact path='/terms' element={<Terms />} />
                  </>
                  
                  :""}
                  {/* <Route exact path="/review" element={<Review />} /> */}
                  <Route path='/review/:hashKey' element={<Review />} />

                </Routes>
              }
              {
                
                
              }
            </Container>


          </div>
        </SettingsContext.Provider>
      </Router>
    }



  </>;
}

export default App;
