// Node Modules
import React from 'react'
import {
    Row, Col,
  Button, FormGroup, FormFeedback,  Input, Label, InputGroup, InputGroupText,
} from 'reactstrap';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { BURKE_URGENCY_TYPES } from '../../constants';


function Step2(props) {
  return <><Row className='justify-content-center'>
    <Col sm="12" md="12" lg="6">


      <FormGroup>

        {/* How Soon */}
        <Label for="how_soon">
          How soon you need this service?
        </Label>
        <Input invalid={props.theUrgencyError} value={props.theUrgency} onChange={e => {
          props.setUrgencyError(false);
          props.setUrgency(e.target.value)
        }} id="how_soon" name="select" type="select">
          <option value="">--</option>
        {
          BURKE_URGENCY_TYPES.map((v,i) => <option value={i+1}>{v}</option>)
        }
        </Input>
        <FormFeedback>  Please select how soon you want this job get done? </FormFeedback>
      </FormGroup>

      {/* Budget */}
      <FormGroup>
        <Label for="budget">
          Your Budget?
        </Label>
        <InputGroup>
          <InputGroupText>$</InputGroupText>
          <Input invalid={props.theBudgetError} type="number" value={props.theBudget} onChange={e => {
            props.setBudgetError(false);
            props.setBudget(e.target.value)
          }} id="budget" placeholder="Ex: 150" />
          <FormFeedback>Please enter your budget </FormFeedback>
        </InputGroup>
      </FormGroup>


      <FormGroup>
        <Label for="description">
          Job Description
        </Label>
        <Input id="jobDescription" invalid={props.theJobDescriptionError} value={props.theJobDescription} onChange={e => {
          props.setJobDescriptionError(false);
          props.setJobDescription(e.target.value)
        }} name="text" type="textarea" placeholder='Please explain more about the job' />
        <FormFeedback>Please provide details about the job</FormFeedback>
      </FormGroup>


      <FormGroup className='text-center'>
        <Button className='mb-2' color="primary" size="lg" block onClick={e => props.__handleSubmitStep2(e)} style={{ height: 60 }}>
          {props.isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : <> Next  <FontAwesomeIcon icon={faArrowRight} /></>}
        </Button>
        <a className='href-back' href={() => false} onClick={() => props.setCurrentStep(1)}>Back</a>
      </FormGroup>
    </Col>
  </Row>
  </>
}


export default Step2;