
// Node Modules
import React from 'react'
import {
  Row, Col, 
  Button, FormGroup, Label, 
} from 'reactstrap';
import Autocomplete from "react-google-autocomplete";
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; 

// Project Constants
import {
  BURKE_GOOGLE_AUTOCOMPLETE_API_KEY
} from '../../constants';

function Step1(props) {
  return <>
    <Row className='justify-content-center text-center'>
      <Col sm="12" md="12" lg="6">
        <FormGroup>
          <Label for="address">
            Please enter the address of the location you want this service for
          </Label>
          <Autocomplete
            options={{ types: [], componentRestrictions: { country: "us" } }}
            id="address"
            value={props.theSearchAddress}
            autoComplete="off"
            className={"form-control " + (props.theSearchAddressError ? 'is-invalid' : '')}
            placeholder='Start typing to lookup your address'
            onChange={e => {
              props.setSearchAddressError(false);
              props.setSearchAddress(e.target.value)
            }}
            onPlaceSelected={(place) => props.__handlePlaceSelected(place)}
            apiKey={BURKE_GOOGLE_AUTOCOMPLETE_API_KEY}
          />
          {props.theSearchAddressError && <div class="invalid-feedback">Please find and select your Google verified address or Zip code above</div>}
        </FormGroup>

        <FormGroup>
          <Button color="primary" size="lg" block onClick={e => props.__handleSubmitStep1(e)} style={{ height: 60 }}>
            {props.isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : <> Next  <FontAwesomeIcon icon={faArrowRight} /></>}
          </Button>
        </FormGroup>
      </Col>
    </Row>
  </>
}

export default Step1;