import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Help() {



  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <h1 aria-label='Services'>Terms and Conditions</h1>
        <div className='subheading'></div>
      </Row>
    </>
  }
  






  return <>

    <Container className='page page-services bg-gray' fluid>
      <HEADER />
      <Container>
        <Row>
          <Col md="12">
            
          </Col>
        </Row>
      </Container>
    </Container>
  </>
}
export default Help;