
// Node Modules
import React, { } from 'react'
import {
  Row, Col,
  Button, FormGroup, FormFeedback, FormText, Input, Label,
} from 'reactstrap';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Step4(props) {
  return <Row className='justify-content-center'>
    <Col sm="12" md="12" lg="6">
      <p className='mt-6'><strong>Please provide your contact information</strong></p>

      <FormGroup>
        <Label for="full_name">Your Name</Label>
        <Input invalid={props.theFullNameError} value={props.theFullName} onChange={e => {
          props.setFullNameError(false);
          props.setFullName(e.target.value)
        }} id="full_name" />
        <FormFeedback>Please enter your full name</FormFeedback>
      </FormGroup>


      <FormGroup>
        <Label for="email">Your Email</Label>
        <Input invalid={props.theEmailError} type="email" value={props.theEmail} onChange={e => {
          props.setEmailError(false);
          props.setEmail(e.target.value)
        }} id="email" />
        <FormFeedback> A valid email address is required </FormFeedback>
        <FormText>This is where you will get job updates</FormText>
      </FormGroup>


      <FormGroup>
        <Label for="phone">Your Phone Number</Label>
        <Input invalid={props.thePhoneNumberError} value={props.thePhoneNumber} onChange={e => {
          props.setPhoneNumberError(false);
          props.setPhoneNumber(e.target.value)
        }} id="phone" />
        <FormFeedback> A valid phone number is required </FormFeedback>
        <FormText>You may receive call or text messages on this number.</FormText>
      </FormGroup>

      <FormGroup className='text-center'>
        <Button className='mb-2' color="primary" size="lg" block onClick={e => props.__handleSubmitStep4(e)} style={{ height: 60 }}>
          {props.isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : <> Next  <FontAwesomeIcon icon={faArrowRight} /></>}
        </Button>
        <a className='href-back' href={() => false} onClick={() => props.setCurrentStep(3)}>Back</a>
      </FormGroup>
    </Col>
  </Row>
}

export default Step4;