function ServiceContent(){
    return <>
        <div className="container">
            <div class="services">
                <div class="container">
                    <div class="inner-serv">
                        <div class="row j-center">
                            <div class="col-md-10 col-md-offset-1">
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="text-center">
                    <a href="/services" className={`btn-request`}> Request a Contractor that is Burke Certified</a>
                </div> */}
            </div>
        </div>
    </>
}
export default ServiceContent