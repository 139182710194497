
// Node Modules
import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import {
  Container, Row, Col, Alert,
  Button, FormGroup, FormFeedback, FormText, Input, Label,
} from 'reactstrap';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import PasswordStrengthBar from 'react-password-strength-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Project Components
import { SettingsContext } from '../../App';
import Loader from '../../Components/Loader';
import RequestFailed from '../../Components/RequestFailed';

// Project Constants
import { isEmailValid } from '../../helpers';
import { BURKE_BASE_API_URL, BURKE_ADMIN_BASE_URL } from '../../constants';
import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';




function Register() {

  const settings = useContext(SettingsContext);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isSignupRequestSuccess, setSignupRequestSuccess] = useState(false);
  const [theSignupResponse, setSignupResponse] = useState('');

  // States
  const [isLoading, setLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(true);
  const [theRequestErrorCode, setRequestErrorCode] = useState(0);

  const [thePassword, setPassword] = useState('');
  const [thePasswordScore, setPasswordScore] = useState(0);
  const [thePasswordError, setPasswordError] = useState(false);

  const [theFullName, setFullName] = useState('');
  const [theFullNameError, setFullNameError] = useState(false);

  const [theBusinessName, setBusinessName] = useState('');
  const [theBusinessNameError, setBusinessNameError] = useState(false);

  const [theEmail, setEmail] = useState('');
  const [theEmailError, setEmailError] = useState(false);

  const [thePhoneNumberError, setPhoneNumberError] = useState(false);
  const [thePhoneNumber, setPhoneNumber] = useState('');

  const [theAgreementError, setAgreementError] = useState(false);
  const [theAgreement, setAgreement] = useState(false);


  useEffect(() => {
    document.title = 'Become a Provider - ' + settings.app_name;
  }, []);





  const __handleSubmit = (e) => {
    let error = false;

    if (!theBusinessName) { error = true; setBusinessNameError(true); }
    if (!theFullName) { error = true; setFullNameError(true); }
    if (!theEmail || !isEmailValid(theEmail)) { error = true; setEmailError(true); }
    if (!thePhoneNumber) { error = true; setPhoneNumberError(true); }
    if (!thePassword) { error = true; setPasswordError(true); }
    if (thePassword.length <= 6) { error = true; setPasswordError(true); }
    if (!theAgreement) { error = true; setAgreementError(true); }
    // if (!theSearchAddress) { error = true; setSearchAddressError(true); }

    if (!error) {
      setSubmitting(true);

      let url = BURKE_BASE_API_URL + '/providers';

      axios.post(url, {
        business_name: theBusinessName,
        full_name: theFullName,
        email: theEmail,
        password: thePassword,
        phone: thePhoneNumber,
        remember: false,
      }, {
        'content-type': 'multipart/form-data',
      })
        .then(function (response) {
          setSubmitting(false);
          setSignupRequestSuccess(true);
          window.location.href = `${BURKE_ADMIN_BASE_URL}/login`;
        })
        .catch(function (error) {
          setLoading(false);
          setSubmitting(false);
          setSignupRequestSuccess(false);
          setSignupResponse(error.message); 
        }); 
    }
  }

  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <h1>Become a Provider</h1>
        <div className='subheading'>Please submit following form to be registered as a provider on Burke Contractors</div>
      </Row>
    </>
  }

  return <>
    <Container className={`page page-provider-signup bg-gray`} fluid style={{ paddingBottom: 200 }}>
      <HEADER />
      <Container>
        <Row className={`pt-8 justify-content-center`}>
          <Col lg="6">
            {
              isLoading ? <Loader /> : (!isRequestSuccess ? <RequestFailed errorCode={theRequestErrorCode} /> : '')
            }
            {
              !isLoading && <>
                <FormGroup>

                  {/* How Soon 
                <Label for="how_soon">
                  How soon you need this service?
                </Label>
                <Input invalid={theHowSoonError} value={theHowSoon} onChange={e => {
                  setHowSoonError(false);
                  setHowSoon(e.target.value)
                }} id="how_soon" name="select" type="select">
                  <option value="asap">Ready to Hire</option>
                  <option value="month">Within a Month</option>
                  <option value="planning">Just Planning</option>
                </Input>
                <FormFeedback>  Please select how soon you want this job get done? </FormFeedback>*/}
                </FormGroup>

                {/* BusinessName */}
                <FormGroup>
                  <Label for="business-name">
                    Business Name
                  </Label>
                  <Input invalid={theBusinessNameError} type="text" value={theBusinessName} onChange={e => {
                    setBusinessNameError(false);
                    setBusinessName(e.target.value)
                  }} id="business-name" placeholder="Ex: Acme Home Services" />
                  <FormFeedback>Please enter your business name </FormFeedback>
                </FormGroup>

                {/* Full Name */}
                <FormGroup>
                  <Label for="full_name">Full Name</Label>
                  <Input invalid={theFullNameError} value={theFullName} onChange={e => {
                    setFullNameError(false);
                    setFullName(e.target.value)
                  }} id="full_name" />
                  <FormFeedback>Please enter your full name</FormFeedback>
                </FormGroup>


                <FormGroup>
                  <Label for="phone">Your Phone Number</Label>
                  <Input invalid={thePhoneNumberError} value={thePhoneNumber} placeholder="Ex: (111) 222-3333" onChange={e => {
                    setPhoneNumberError(false);
                    setPhoneNumber(e.target.value)
                  }} id="phone" />
                  <FormFeedback> A valid phone number is required </FormFeedback>
                  <FormText>You may receive call or text messages on this number.</FormText>
                </FormGroup>


                <FormGroup>
                  <Label for="email">Your Email</Label>
                  <Input invalid={theEmailError} type="email" value={theEmail} onChange={e => {
                    setEmailError(false);
                    setEmail(e.target.value)
                  }} id="email" />
                  <FormFeedback> A valid email address is required </FormFeedback>
                  <FormText>This is your login ID as well, and here you will get job updates</FormText>
                </FormGroup>


                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input invalid={thePasswordError} type="password" value={thePassword} onChange={e => {
                    setPasswordError(false);
                    setPassword(e.target.value)
                  }} id="password" />
                  <FormFeedback> A strong password of 7 or more characters is required </FormFeedback>
                </FormGroup>

                <PasswordStrengthBar password={thePassword} onChangeScore={(score) => setPasswordScore(score)} />

                <FormGroup check className='mb-3'>
                  <Input invalid={theAgreementError} id="agree" type="checkbox" checked={theAgreement} onChange={e => {
                    setAgreementError(false);
                    setAgreement(!theAgreement)
                  }} />
                  {' '}
                  <Label check for="agree">
                    I agree to <Link exact to="/terms" target="_blank">Terms and Conditions</Link> 
                  </Label>
                  <FormFeedback> You must agree to Burke Contractors terms and conditions. </FormFeedback>
                </FormGroup>

                {
                  isSignupRequestSuccess && <Alert color="success">
                    <FontAwesomeIcon icon={faCircleCheck} /> Thank you. Your request has been submitted. We'll review your request and get back to you. Please keep an eye on your inbox.
                  </Alert>
                }


                {
                  !isSignupRequestSuccess && theSignupResponse && <Alert color="danger">
                    <FontAwesomeIcon icon={faExclamationCircle} /> Error: {theSignupResponse}.
                  </Alert>
                }


                <FormGroup>
                  <Button color="primary" size="lg" block onClick={e => __handleSubmit(e)} style={{ height: 60 }}>
                    {isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : 'Submit'}
                  </Button>
                </FormGroup>

                <FormGroup className='text-center'>
                  <small>Already have an account? <a href={`${BURKE_ADMIN_BASE_URL}/login`}>Login</a></small>
                </FormGroup>

              </>
            }
          </Col>
        </Row>
      </Container>
    </Container>
  </>
}

export default Register;