// Node Modules
import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Alert } from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";

// Project Components
import { SettingsContext } from '../../App';
import Loader from '../../Components/Loader';
import RequestFailed from '../../Components/RequestFailed';

// Project Constants
import { BURKE_BASE_API_URL } from '../../constants';








function Services() {

  const settings = useContext(SettingsContext);
  document.title = 'Services - ' + settings.app_name;

  // States
  const [isLoading, setLoading] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(true);
  const [theRequestErrorCode, setRequestErrorCode] = useState(0);
  const [theServices, setServices] = useState([]);






  useEffect(() => {
    let params = '';
    let url = BURKE_BASE_API_URL + '/categories' + (params ? '?' + params : '');

    axios.get(url, {}).then((response) => {

      setLoading(false);
      setIsRequestSuccess(true);

      if (response.data.success) {
        let categories = response.data.data;
        let services = [];

        categories.forEach((category, i) => {
          // let icon = '';
          // if (category.has_media && category.media.length) {
          //   icon = category.media[0].url;
          // }

          services.push({
            id: category.category_id,
            parent_id: category.parent_id,
            name: category.name,
            description: category.description,
            featured: category.featured,
            color: category.color,
            order: category.order,
            icon: category.icon,
            slug: category.slug
          })
        })

        setServices(services);
      }
    }).catch(function (error) {
      setLoading(false);
      setIsRequestSuccess(false);

      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setRequestErrorCode(error.response.status);

      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);

      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  }, []);
  






  const SERVICES_NOT_FOUND = () => {
    return <Alert color="danger">No services found</Alert>
  }
  






  const HEADER = () => {
    return <>
      <Row className='page-header style1'>
        <h1 aria-label='Services'>Services</h1>
        <div className='subheading'>Burke Contractors deal in following services. What would you like to get done?</div>
      </Row>
    </>
  }
  






  const SERVICES = () => {
    return <>

      <Container>
        <Row className='page-body'>

          {
            theServices.map((service, i) => <Col md="4" className=''>
              <Link exact to={`/services/${service.slug}`} className="nav-link" activeClassName="active">
                <div className="svcbx bg-white">
                  {service.icon && <div className="pic"><img alt="icon" src={service.icon} /></div>}
                  <h2>{service.name}</h2>
                </div>
              </Link>
            </Col>)
          }
        </Row>
      </Container>
    </>
  }
  






  return <>

    <Container className='page page-services bg-gray' fluid>
      <HEADER />
      <Container>
        <Row className={`pt-8`}>
          <Col md="12">
            {
              isLoading ? <Loader /> : (!isRequestSuccess ? <RequestFailed errorCode={theRequestErrorCode} /> : (!theServices ? <SERVICES_NOT_FOUND /> : <><SERVICES /></>))
            }
          </Col>
        </Row>
      </Container>
    </Container>
  </>
}
export default Services;