import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, 
} from 'reactstrap';
function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return <div>

    <Navbar expand="lg">
      <NavbarBrand href="/">Burke Contractors</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="me-auto" navbar>
          <NavItem>
            <Link exact to="/" className="nav-link" activeClassName="active">Home</Link>
          </NavItem>
          <NavItem>
            <Link exact to="/services" className="nav-link" activeClassName="active">Services</Link>
          </NavItem>
          <NavItem>
            <Link exact to="/providers-directory" className="nav-link" activeClassName="active">Providers Directory</Link>          
          </NavItem>  
          <NavItem>
            <Link exact to="/register" className="nav-link" activeClassName="active">Become a Provider</Link>
          </NavItem>
          <NavItem>
            <Link exact to="/help" className="nav-link" activeClassName="active">Help and Support</Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>


  </div>
}
export default Navigation