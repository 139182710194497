// Node Modules
import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import { Container, Row, Col, Alert, ButtonGroup, Button, FormGroup, Input, PhoneInput } from 'reactstrap';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'; // InfoWindow,
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import { Rating } from 'react-simple-star-rating';

// Project Components
import { SettingsContext } from '../../App';
import Loader from '../../Components/Loader';
import RequestFailed from '../../Components/RequestFailed';
import Sidebar from './Sidebar';
import Header from './Header';


import { faCircleCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// Project Constants
import { LOCAL_BURKE_BASE_API_URL, BURKE_BASE_API_URL, BURKE_GOOGLE_MAP_API_KEY } from '../../constants';











function ProviderProfile(props) {

  const settings = useContext(SettingsContext);

  let { providerID } = useParams();
  if (typeof providerID === 'undefined' && props.hasOwnProperty('provider') && props.provider.hasOwnProperty('provider_id') && props.provider.provider_id) providerID = props.provider.provider_id;

  let { serviceID } = useParams();
  if (typeof serviceID === 'undefined' && props.hasOwnProperty('service_id') && props.service_id) serviceID = props.service_id;

  const [isLoading, setLoading] = useState(true);
  
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [theRequestErrorCode, setRequestErrorCode] = useState(false);
  const [theProvider, setProvider] = useState({});
  const [theLat, setLat] = useState(0.0);
  const [theLng, setLng] = useState(0.0);
  const [theSelectedServingZipcode, setSelectedServingZipcode] = useState(0);
  const [theGallery, setGallery] = useState([]);
  const [theReviews, setReviews] = useState({});

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [ratingError, setRatingError] = useState('');
  const [commentError, setCommentError] = useState('');

  const [theName, setName] = useState('');
  const [theComment, setComment] = useState('');
  const [theRating, setRating] = useState('');
  const [theReviewFormSubmit, settheReviewFormSubmit] = useState(false);

  let [theService, setService] = useState('');
  let [theProviderName, setProviderName] = useState('');
  let [theCustomerName, setCustomerName] = useState('');
  let [theHash, setHashToken] = useState('');
  let [theErrorMessage, setErrorMsg] = useState(true);

  // Get the provider details
  useEffect(() => {

    document.title = settings.app_name;

    let params = '';
    let url = BURKE_BASE_API_URL + '/providers/' + providerID + (params ? '?' + params : '');
    axios.get(url, {}).then((response) => {
      // console.log(response);

      setLoading(false);
      setIsRequestSuccess(true);

      if (response.data.success) {
        let provider = response.data.data;
        if (provider.business.business_service_zip_codes.length) {
          setLat(provider.business.business_service_zip_codes[0].latitude);
          setLng(provider.business.business_service_zip_codes[0].longitude);
          setSelectedServingZipcode(provider.business.business_service_zip_codes[0].zip_codes);

        }
        setReviews(provider.the_reviews.review);
        setProvider(provider);
        console.log(provider.the_reviews.review);





        // const images = [
        //   {
        //     original: 'https://api.lorem.space/image/furniture?w=1000&h=500',
        //     thumbnail: 'https://api.lorem.space/image/furniture?w=250&h=150',
        //   },
        //   {
        //     original: 'https://api.lorem.space/image/pizza?w=1000&h=500',
        //     thumbnail: 'https://api.lorem.space/image/pizza?w=250&h=150',
        //   },
        // ];

        if (provider.business.gallery.length) setGallery(provider.business.gallery);

      }
    }).catch(function (error) {
      setLoading(false);
      setIsRequestSuccess(false);
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setRequestErrorCode(error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
  }, []);






  useEffect(() => {
    if (theProvider.hasOwnProperty('name')) document.title = theProvider.name + ' - ' + settings.app_name;
  }, [theProvider]);






  const PROVIDER_NOT_FOUND = () => {
    return <Alert color="danger">Provider not found</Alert>
  }

  const getReview = (rating) => {
    switch (rating) {
      case 1:
        return 'Poor';
        break;

      case 2:
        return 'Average';
        break;

      case 3:
        return 'Okay';
        break;

      case 4:
        return 'Satisfied';
        break;

      default:
        return 'Excellent';
        break;
    }
  }




  const PROVIDER_PROFILE = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      rating: "",
      comment: ""
    });

    // const { name, rating, comment } = formData;

    // const handleChange = (e) => {
    //   e.preventDefault();
    //   setFormData({ ...formData, [e.target.name]: e.target.value });
    // };


    const handleChange = (e) => {
      const { name, value } = e.target;
    
      // Validate phone field
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
    

    const handleSubmit = (e) => {
      e.preventDefault();

      console.log("formData =>", formData);
      console.log('prov', theProvider);
      const { name, email, phone, rating, comment } = formData;

      let hasError = false;

      if (phone && !/^\d+$/.test(phone)) {

        setPhoneError('Phone field must contain numbers only');
        hasError = true;
      } else {
        setPhoneError('');
      }
      if (email && !/@/.test(email)) {
          setEmailError('Email must contain "@" symbol');
          hasError = true;

      } else {
          setEmailError('');
      }
      if (!name && !email && !phone && !rating && !comment ) {
        
        setNameError("Name field is required");
        setEmailError("Email field is required");
        setPhoneError("Phone field is required");
        setRatingError("Rating field is required");
        setCommentError("Comment field is required");
        hasError = true;
      } else if (!name && !email) {
        setNameError("Name field is required");
        setEmailError("Email field is required");
        hasError = true;
      } else if (!email && !phone) {
        setEmailError("Email field is required");
        setPhoneError("Phone field is required");
        hasError = true;
      } else if (!phone && !rating) {
        setPhoneError("Phone field is required");
        setRatingError("Rating field is required");
        hasError = true;
      } else if (!name && !rating) {
        setNameError("Name field is required");
        setRatingError("Rating field is required");
        hasError = true;
      } else if (!name && !comment) {
        setNameError("Name field is required");
        setCommentError("Comment field is required");
        hasError = true;
      } else if (!rating && !comment) {
        setRatingError("Rating field is required");
        setCommentError("Comment field is required");
        hasError = true;
      } else {
        setNameError('');
        setRatingError('');
        setCommentError('');
      }

      if (!name) {
        setNameError("Name field is required");
        hasError = true;
      } else {
        setNameError('');
      }

      if (!email) {
        setEmailError("Email field is required");
        hasError = true;
      } else {
        setEmailError('');
      }
      if (!phone) {
        setPhoneError("Phone field is required");
        hasError = true;
      } else {
        setPhoneError('');
      }

      if (!rating) {
        setRatingError("Rating field is required");
        hasError = true;
      } else {
        setRatingError('');
      }

      if (!comment) {
        setCommentError("Comment field is required");
        hasError = true;
      } else {
        setCommentError('');
      }

      if (hasError) {
        return;
      }

      let review_post_url = BURKE_BASE_API_URL + '/provider-review';
      axios.post(review_post_url, {
        customer_name: name,
        customer_email: email,
        customer_phone: phone,

        provider_id: theProvider.provider_id,
        provider_first_name: theProvider.first_name,
        provider_last_name: theProvider.last_name,
        provider_email: theProvider.email,

        comment: comment,
        rating: rating,
      })
        .then(function (response) {
          if (response.data.success) {
            settheReviewFormSubmit(true);
            setFormData({ name: '', email: '', phone:'', rating: '', comment: '' });
          }
        })
        .catch(function (error) {
          // Handle error
        });
    };
    


    return <>
      <Col md="8">
        {/* <Link exact to={`/services/${serviceID}/providers/`} className="nav-link text-centerx mb-3" activeClassName="active"><FontAwesomeIcon icon={faArrowLeft} /> Back to Providers</Link> */}
        <Header provider={theProvider} />
        <Row>
          <Col md="12">
            {
              theProvider.business.hasOwnProperty('gallery') && theProvider.business.gallery.length >= 1 && <Row className='projects mb-8'>
                <Col>
                  <ImageGallery items={theGallery} />
                </Col>
              </Row>
            }





            <Row className='intro mb-9'>
              <Col>
                {/* <h2>Introduction</h2> */}
                <p>{theProvider.business.about}</p>
              </Col>
            </Row>






            {
              theProvider.payment_methods.length > 0 && <Row className='payment-methods mb-8'>
                <Col>
                  <h2>Accepted Payment Methods</h2>
                  <ul>
                    {
                      theProvider.payment_methods.map((p, i) => <li key={i}>{p.name}</li>)
                    }
                  </ul>
                </Col>
              </Row>
            }






            {
              theProvider.business.business_service_zip_codes && theProvider.business.business_service_zip_codes.length > 0 && <><Row className='payment-methods mb-8'>
                <Col>
                  <h2>Service Areas</h2>
                  <div className='service-areas no-transition-at-all' style={{ padding: 5, border: '1px solid #ccc', borderRadius: 7 }}>
                    <Map google={props.google} zoom={10} disableDefaultUI={true}
                      containerStyle={{
                        position: 'relative',
                        width: '100%',
                        height: '300px'
                      }}
                      center={{
                        lat: theLat,
                        lng: theLng
                      }}
                      initialCenter={{
                        lat: theLat,
                        lng: theLng
                      }}
                    >
                      {
                        theProvider?.business?.business_service_zip_codes.map((zip, i) => <Marker onClick={() => { }} name={zip.zip_codes} position={{ lat: zip.latitude, lng: zip.longitude }} />)
                      }
                      {/* <Marker onClick={onMarkerClick} name={'Current location'} position={{ lat: props.theLat, lng: props.theLng }} /> */}
                      {/* <InfoWindow onClose={onInfoWindowClose}>
                    <div>
                      <h1>My place name</h1>
                    </div>
                  </InfoWindow> */}
                    </Map>
                    <div className='text-center'>
                      <ButtonGroup className="my-2">
                        {
                          theProvider?.business?.business_service_zip_codes.map((zip, i) => <Button onClick={(e) => {
                            setLat(zip.latitude);
                            setLng(zip.longitude);
                            setSelectedServingZipcode(zip.zip_codes);
                          }} outline={
                            theSelectedServingZipcode !== zip.zip_codes
                          } color="primary" >{zip.zip_codes}</Button>)
                        }
                      </ButtonGroup>
                      <div><small style={{ fontSize: 8, color: '#555' }}>{theLat}, {theLng}</small></div>
                    </div>
                  </div>
                </Col>
              </Row>
              </>
            }
          </Col>
        </Row>
      </Col>
      <Col md="4">
        <Sidebar provider={theProvider} serviceID={serviceID} />
      </Col>

      <Row className='justify-content-left'>
        <Col sm="12" md="12" lg="6">
          <div class="review-form">
            <h2>Submit a review</h2>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Input type="text"
                  placeholder="Enter your name"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange} />
                  {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
              </FormGroup>
              <FormGroup>
                <Input type="email"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange} />
                  {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
              </FormGroup>
              <FormGroup>
              <Input type="tel"
                  placeholder="Enter your phone number"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({...formData, phone: e.target.value.replace(/[^0-9.]/g, '')})} />
                {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
              </FormGroup>
              <FormGroup>
                <Input id="rating" type="select"
                  name="rating"
                  value={formData.rating}
                  onChange={handleChange}
                >
                  <option value="">-Select a rating-</option>
                  <option value="1">1 - Poor</option>
                  <option value="2">2 - Average</option>
                  <option value="3">3 - Okay</option>
                  <option value="4">4 - Satisfactory</option>
                  <option value="5">5 - Excellent </option>
                </Input>
                {ratingError && <p style={{ color: 'red' }}>{ratingError}</p>}

              </FormGroup>
              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}


              <FormGroup className='mt-4'>
                <Input type="textarea" rows="7" max="500"
                  placeholder="Enter your comments here (max 500 characters)"
                  id="comment" name='comment'
                  value={formData.comment} onChange={handleChange} />
              {commentError && <p style={{ color: 'red' }}>{commentError}</p>}

              </FormGroup>

              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}


              {
                  theReviewFormSubmit && <Alert color="success">
                    <FontAwesomeIcon icon={faCircleCheck} /> Thank you for sharing your experience with us.
                  </Alert>
              }

              <FormGroup className='text-center'>
                {/* <button className='mb-2' color="primary" type="submit" size="lg" block style={{ height: 60 }}>Submit</button> */}

                <Button className='mb-2' color="primary" type="submit" size="lg" block style={{ height: 60 }}>
                  <> Submit </>
                </Button>
              </FormGroup>
            </form>

          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="12" lg="6">
          {theReviews && theReviews.length > 0 && (
            <h2>Customer Reviews</h2>
          )}

          {/* {
            theReviews.map((r, k) =>
              <div className="the_review">
                <p><strong>{r.customer_name}</strong></p>
                <div className="ratings">
                  
                  <strong>{getReview(r.rating)} <Rating style={{ marginTop: -6 }} size={18} initialValue={r.rating} readonly />  </strong><span className="stars">
                  </span></div>
                <p>{r.comment}</p>
              </div>

            )
          }  */}

          {theReviews && theReviews.length > 0 && (
            theReviews.map((r, k) =>
              <div className="the_review" key={k}>
                <p><strong>{r.customer_name}</strong></p>
                <div className="ratings">
                  <strong>{getReview(r.rating)} <Rating style={{ marginTop: -6 }} size={18} initialValue={r.rating} readonly />  </strong><span className="stars"></span>
                </div>
                <p>{r.comment}</p>
              </div>
            )
          )}

        </Col>
      </Row>
    </>
  }






  return <>

    <Container className={`page page-provider page-provider-${providerID} bg-white pt-8`} fluid>
      <Container>
        <Row>
          <Col md="12">
            {
              isLoading ? <Loader /> : !isRequestSuccess ? <RequestFailed errorCode={theRequestErrorCode} /> : !theProvider ? <PROVIDER_NOT_FOUND /> : <Row><PROVIDER_PROFILE /></Row>
            }
          </Col>
        </Row>
      </Container>
    </Container>
  </>
}

export default GoogleApiWrapper({
  apiKey: BURKE_GOOGLE_MAP_API_KEY
})(ProviderProfile)