function RequestBar(){
    return <>
        <div class="request">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="inner-contracor border-r">
                            <div class="contractor-content">
                                <div class="contr-link">
                                    <h3>
                                        <a href="/services">Request a Contractor</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-contracor">
                            <div class="contractor-content">
                                <div class="contr-link">
                                    <h3>
                                        <a href="/register">CONTRACTOR SIGN UP</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default RequestBar