import {FormGroup, Input} from 'reactstrap';
function BannerContent(){
    return <>
        <div className="banner-content">
            <div className="banner-heading">
                <h1>LET BURKE DO THE WORK</h1>
            </div>
            <div className="banner-service">
                <p>Start by searching for your project</p>
                <div name="service" id="service" className="service">
                    {/* <FormGroup>
                        <Input className="search-field form-control my-0 py-1 amber-border slidecategories fs-sm-14 fs-lg-18" type="text" placeholder="Search" aria-label="Search" />
                    </FormGroup > */}
                    <div className="text-center">
                        <a href="/services" className={`btn-request`}> Request a Contractor that is Burke Certified</a>
                    </div>
                    <div className="showresults hidden"></div>
                </div>
                <div className="blue-line"></div>
            </div>
            <div className="banr-more-servc">
                {/* <p>Or click on a service below:</p> */}
            </div>
        </div>
    </>
}
export default BannerContent;