
import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { SettingsContext } from '../../App';

import { BURKE_BASE_API_URL } from '../../constants';

//Home Components
import Banner from './components/Banner';
import WhyBurke from './components/WhyChooseBurke';
import RequestBar from './components/RequestBar';
import Footer from './components/Footer';
import ServiceContent from './components/ServiceContent';
import axios from 'axios';

function Home() {

    const settings = useContext(SettingsContext);
    useEffect(() => {
        document.title = settings.app_name;
    }, []);
    return <Container className={`page page-home bg-orange`} fluid>
            <Banner />
                <Container>
                </Container>
            <ServiceContent/>
            <WhyBurke/>
            <RequestBar />
            <Footer />
    </Container>
}
export default Home;