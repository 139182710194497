
// Node Modules
import React, { useRef, useState } from 'react'
import {
  Row, Col,
  Button, FormGroup, Label,
} from 'reactstrap';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faUpload } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import useFileUpload from 'react-use-file-upload';
import axios from 'axios';
import base64 from 'base-64';

import { BURKE_BASE_API_URL } from '../../constants';
import { data } from 'browserslist';

function Step3(props) {

  // Misc
  let maxFilesAllowed = 3;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [theSubmittedSuccesfully, setSubmittedSuccesfully] = useState(false);
  const [theResponseMessage, setResponseMessage] = useState('');
  const [theUploadFilesFirstError, setUploadFilesFirstError] = useState(false);

  // File Upload
  const {
    files,
    handleDragDropEvent,
    setFiles,
    removeFile,
    clearAllFiles
  } = useFileUpload();
  const inputRef = useRef();

  const UPLOAD_FILES = () => {
    return <div className="form-container file-upload mb-3">

      {/* Provide a drop zone and an alternative button inside it to upload files. */}
      <div
        className='dropzone'
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(e) => {
          handleDragDropEvent(e);
          setFiles(e, 'a');
        }}
      >
        <p>Drag and drop files here</p>

        <button className='btn btn-warning' onClick={() => inputRef.current.click()}>or Click to select a file</button>

        {/* Hide the crappy looking default HTML input */}
        <input
          ref={inputRef}
          type="file"
          name="files[]"
          multiple
          style={{ display: 'none' }}
          onChange={(e) => {
            setFiles(e, 'a');
            inputRef.current.value = null;
          }}
        />
      </div>

      {/* Display the files to be uploaded */}
      <div className='files'>
        <ul>
          {files.map((file, i) => (
            <li key={i}>
              <span className='idx'>{i + 1}</span>
              <span className="name">{file.name} </span>
              <span className='right'>
                <span className='size'>{parseFloat(file.size / 1024).toFixed(2)} KB</span>
                <span className='remove' onClick={() => removeFile(file.name)}>
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>

    </div>
  }







  const __handleUpload = (e) => {

    let url = BURKE_BASE_API_URL + '/leads/media-manager/uploads';

    let dataFiles = [];
    var reader = new FileReader();

    if (files.length) {
      setUploading(true);
      setUploadFilesFirstError(false);
      // props.setSubmitting(true);

      function readFile(index) {
        if (index >= files.length) {
          data = new FormData();
          data.append('files', JSON.stringify(dataFiles));
          axios.post(url, data, {
            'content-type': 'multipart/form-data',
          })
            .then(function (response) {

              // props.setSubmitting(false);
              setUploading(false);

              setResponseMessage(response.data.message);
              if (response.data.success) {
                setSubmittedSuccesfully(true);

                let media = response.data.data.map((m, i) => {
                  if(m.hasOwnProperty('url') && m.url && m.url.split('.').pop() === 'mp4') {
                    m.thumb_url = '/assets/video.jpg';
                  } else m.thumb_url = m.url;
                  return m;
                })


                props.setUploadedMedia([...props.theUploadedMedia, ...media]);
                clearAllFiles();
              }

            })
            .catch(function (error) {
              setSubmitting(false);
              console.log(error);
            });
        }
        var file = files[index];
        reader.onload = function (e) {
          dataFiles.push(base64.encode(e.target.result));
          readFile(index + 1);
          console.log(dataFiles);
        }
        reader.readAsBinaryString(file);
      }
      readFile(0);

    }
  }




  const __handleDeleteMedia = (i) => {
    let uploadedMeda = [...props.theUploadedMedia];
    uploadedMeda.splice(i, 1);
    props.setUploadedMedia(uploadedMeda);
  }


  const cssError = {
    color: 'red'
  }



  return <><Row className='justify-content-center'>
    <Col sm="12" md="12" lg="6">


      <FormGroup>
        <Label for="budget">
          Please attach upto {maxFilesAllowed} files
        </Label>
        <UPLOAD_FILES />
        {(props.theFileCountError || props.theFileSizeError) ? <div class="invalid-feedback" style={{ display: 'block' }}>You can only select upto {maxFilesAllowed} files. Maximum filesize {maxFilesAllowed} MB. </div> : ''}
      </FormGroup>




      {
        files.length > 0 && <Button outline className='mb-2' color="primary" size="sm" block onClick={e => __handleUpload(e)} style={{ height: 40 }}>
          {isUploading ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color={isUploading ? '#0a58ca' : '#fff'} /></span> : <> Upload  <FontAwesomeIcon icon={faUpload} /></>}
        </Button>
      }





      <FormGroup className='text-center'>




        {isUploading && <div class="text-center mb-1">Uploading</div>}
        { theUploadFilesFirstError && <div style={cssError}>Please upload the selected files to continue</div> }




        <div className='uploaded-media'>{
          props.theUploadedMedia && props.theUploadedMedia.length >= 0 && props.theUploadedMedia.map((media, i) => <div className={'media media-' + media.media_id + ''}>
            <a href={media.url} target="_blank"><img src={media.thumb_url} width="80" height="80" /></a>
            <a href={() => false} onClick={(e) => __handleDeleteMedia(i)} className="delete" ><FontAwesomeIcon icon={faTimes} /></a>
          </div>)
        }
        </div>


        <Button className='mb-2' disabled={theUploadFilesFirstError} color="primary" size="lg" block onClick={e => {
          setUploadFilesFirstError(false);
          if(files.length > 0) {
            setUploadFilesFirstError(true);
            return;
          }
          props.__handleSubmitStep3(e)
          }} style={{ height: 60 }}>
          {props.isSubmitting ? <span style={{ marginLeft: "-50px", display: "inline-block" }}><InfinitySpin ariaLabel="loading" width='80' color="#fff" /></span> : <> Next  <FontAwesomeIcon icon={faArrowRight} /></>}
        </Button>
        <a className='href-back' href={() => false} onClick={() => props.setCurrentStep(2)}>Back</a>
      </FormGroup>

    </Col>
  </Row></>
}

export default Step3;