import React from 'react'
import { Row, Col, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Rating } from 'react-simple-star-rating'

import defaultLogo from '../../logo.svg';
import { faClock, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Header(props) {
  const socialIcons = {
    'facebook': faFacebook,
    'youtube': faYoutube,
    'twitter': faTwitter
  }
  let theProvider = props.provider;
  console.log(theProvider);

  return <><Row className="header mb-8">

    {
      theProvider.business.hasOwnProperty('logo_url') && theProvider.business.logo_url ? <Col className="logo"><img alt="Site Logo" src={theProvider.business.logo_url} className="App-logo" /></Col> : '' // <img src={defaultLogo} className="App-logo" alt="logo" />
    }

    <Col>
      <h1>{theProvider.business.name}</h1>
      <div className="ratings">
        <strong>Excellent <Rating style={{ marginTop: -6 }} size={18} initialValue={theProvider.rate} readonly /> {theProvider.rate} </strong><span className="stars">
        </span><span className='count'>({theProvider.count_reviews})</span></div>
      <div className='meta'>
        {theProvider.business.location.full_address ? <span className='address'><FontAwesomeIcon icon={faMapMarker} /> {theProvider.business.location.full_address} </span> : ''}
        {theProvider.business.year_established ? <span className='since'><FontAwesomeIcon icon={faClock} /> {new Date().getFullYear() - theProvider.business.year_established * 1} years in business</span> : ''}
      </div>
      <div className='social'>
        <ul>
          {
            theProvider.business.social_profiles_json.map((s, i) => <li key={i}><a href={s.url}><FontAwesomeIcon icon={socialIcons[s.plateform]} /></a></li>)
          }
        </ul>
      </div>
      <div class="services">
        {
          theProvider.business.categories.map((category, i) => <Badge style={{ marginRight: 3 }} color="info"> {category.name} {' '} </Badge>
          )
        }
      </div>
    </Col>
  </Row></>
}
export default Header;